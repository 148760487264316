#halsa-overview{
	padding-top: 0px;
	padding-bottom: 60px;

	.health-page{
		background: #eeeeee;
		margin-top:60px;

		img{
			width: 100%;
			max-width: 100%;
		}

		.slideshow {
		  position: relative;
		  z-index: 1;
		  height: auto;
		  max-width: 100%;
		  width: 100%;
		  margin: 0px auto;
		  @media only screen and (min-width : 992px) {
				margin-left: 15px;
			}
		  
		  * {
		    outline: none;
		  }
		  
		  .slider {
		    box-shadow: none;
		  }
		  
		  .slider-track {
		    transition: all 1s cubic-bezier(0.7, 0, 0.3, 1);
		  }
		  
		  .item {
		    height: 100%;
		    position: relative;
		    z-index: 1;
		    
		    img {
		      width: 100%;
		      transition: all 1s cubic-bezier(0.7, 0, 0.3, 1);
		      transform: scale(1.2);
		    }
		    
		    &.slick-active img {
		      transform: scale(1);
		    }
		  }
		}

		.text-box{
			padding:30px;

			h2{
				text-align: left;
				font-family: $futura-medium;
				color: $dark-blue;
				font-size: 34px;
				margin-bottom: 20px;
			}

			p{
				text-align: left;
				font-family: $futura-book;
				color: $black;
				font-size: 20px;
				margin-bottom: 20px;
			}
		}
	}

	#news-section{
		margin-top:60px;
		text-align: center;

		h2{
			text-align: left;
			font-family: $futura-demibold;
			color: $black;
			font-size: 24px;
			margin-bottom: 20px;

			img{
				display: inline-block;
				margin-left: 10px;
			}
		}

		.news-article{
			text-align: left;

			h3{
				text-align: left;
				font-family: $futura-medium;
				color: $dark-blue;
				font-size: 28px;
				border-bottom:1px solid $black;
				padding-bottom:10px;
				margin-bottom: 10px;
			}

			p{
				text-align: left;
				font-family: $futura-book;
				color: $black;
				font-size: 18px;
				margin-bottom: 40px;

				&.date{
					font-size: 16px;
					float:right;
				}
			}

			a{
				text-align: left;
				font-family: $futura-medium;
				color: $black;
				font-size: 18px;

				img{
					display: inline-block;
					margin-right: 10px;
				}
			}
		}

		.button{
			margin-top: 40px;
		}
	}
}

#halsa-page{
	padding-top: 0px;
	padding-bottom: 60px;

	.health-section{
		background: #eeeeee;
		margin-top:60px;

		img{
			width: 100%;
			max-width: 100%;

			&.right{
				@media only screen and (min-width : 992px) {
					margin-left: 15px;
				}
			}

			&.left{
				@media only screen and (min-width : 992px) {
					margin-left: -15px;
				}
			}
		}

		.text-box{
			padding:30px;

			h2{
				text-align: left;
				font-family: $futura-medium;
				color: $dark-blue;
				font-size: 34px;
				margin-bottom: 20px;
			}

			p{
				text-align: left;
				font-family: $futura-book;
				color: $black;
				font-size: 20px;
				margin-bottom: 20px;
			}
		}
	}

	#main-health-section{
		margin-top: 60px;

		h3{
			text-align: left;
			font-family: $futura-medium;
			color: $dark-blue;
			font-size: 34px;
			margin-bottom: 20px;
		}

		p{
			text-align: left;
			font-family: $futura-book;
			color: $black;
			font-size: 20px;
			margin-bottom: 30px;

			&.references{
				font-size: 16px;
				margin-top: 50px;
			}
		}

		ul{
			margin-bottom: 30px;
			
			li{
				text-align: left;
				font-family: $futura-book;
				color: $black;
				font-size: 20px;
			}
		}

		.share-facebook{
			border-top:2px solid $main-blue;
			margin-top: 20px;

			p{
				text-align: left;
				font-family: $futura-demibold;
				color: $black;
				font-size: 18px;
				margin-top: 20px;
				line-height: 25px;
			}

			img{
				display: inline-block;
				margin-left: 10px;
				height: 25px;
			}
		}
	}

	#related-pages{
		margin-top:60px;
		text-align: center;

		h3{
			text-align: left;
			font-family: $futura-demibold;
			color: $black;
			font-size: 24px;
			margin-bottom: 30px;

			img{
				display: inline-block;
				margin-left: 10px;
			}
		}

		.related-page{
			background:#eeeeee;
			margin-bottom: 30px;

			img{
				max-width: 100%;
				width: 100%;
			}

			.text-box{
				padding:15px;

				h4{
					text-align: left;
					font-family: $futura-medium;
					color: $dark-blue;
					font-size: 28px;
				}

				p{
					text-align: left;
					font-family: $futura-book;
					color: $black;
					font-size: 18px;
				}

				.button{
					width: 100%;
					max-width: 100%;
					margin-top:20px;
				}
			}	
		}

		.button{
			margin-top:50px;
		}
	}
}

