//Colors
$black: #111716;
$light-orange: #e65709;
$dark-orange: #d96b45;
$dark-blue: #2b5366;
$light-blue: #66c6f2;
$main-blue:#4586a5;

//Fonts
$futura-book: futura-book, sans-serif;
$futura-medium: futura-medium, sans-serif;
$futura-demibold: futura-demibold, sans-serif;


@font-face {
    font-family: futura-book;
    src: url("assets/fonts/futura-pt-book-589a6dec272c3.otf");
}

@font-face {
    font-family: futura-medium;
    src: url("assets/fonts/futura-pt-medium-589e45b956de4.otf");
}

@font-face {
    font-family: futura-demibold;
    src: url("assets/fonts/futura-pt-demibold-589e43b852117.otf");
}