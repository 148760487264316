body{
	@media only screen and (max-width : 992px) {
		word-break: break-word;
    	-webkit-hyphens: auto;
       -moz-hyphens: auto;
        -ms-hyphens: auto;
            hyphens: auto;

        h1,h2,h3,h4,h5,h6{
        	word-break: normal;
        	-webkit-hyphens: none;
	       -moz-hyphens: none;
	        -ms-hyphens: none;
	            hyphens: none;
        }
	}
}