#testofilmer{
	padding-top: 0px;
	padding-bottom: 60px;

	.jumbotron{
		a.button{
			margin-right: 10px;
			margin-bottom: 10px;
		}
	}

	.testo-movie{
		padding-top:70px;
		padding-bottom:50px;
		border-bottom:1px solid #eeeeee;

		.embed-container { 
			position: relative; 
			padding-bottom: 56.25%; 
			height: 0; 
			overflow: hidden; 
			max-width: 100%; 
			margin-bottom: 30px;
		} 

		.embed-container iframe, .embed-container object, .embed-container embed { 
			position: absolute; 
			top: 0; 
			left: 0; 
			width: 100%; 
			height: 100%; 
		}

		h2{
			text-align: left;
			font-family: $futura-medium;
			color: $dark-blue;
			font-size: 34px;
			margin-bottom: 20px;
		}

		p{
			text-align: left;
			font-family: $futura-book;
			color: $black;
			font-size: 20px;
			margin-bottom: 0px;
		}
	}

	#news-section{
		margin-top:100px;
		text-align: center;

		h2{
			text-align: left;
			font-family: $futura-demibold;
			color: $black;
			font-size: 24px;
			margin-bottom: 20px;

			img{
				display: inline-block;
				margin-left: 10px;
			}
		}

		.news-article{
			text-align: left;

			h3{
				text-align: left;
				font-family: $futura-medium;
				color: $dark-blue;
				font-size: 28px;
				border-bottom:1px solid $black;
				padding-bottom:10px;
				margin-bottom: 10px;
			}

			p{
				text-align: left;
				font-family: $futura-book;
				color: $black;
				font-size: 18px;
				margin-bottom: 40px;

				&.date{
					font-size: 16px;
					float:right;
				}
			}

			a{
				text-align: left;
				font-family: $futura-medium;
				color: $black;
				font-size: 18px;

				img{
					display: inline-block;
					margin-right: 10px;
				}
			}
		}

		.button{
			margin-top: 40px;
		}
	}
}