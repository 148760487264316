.jumbotron{
	background-image: url('assets/images/bg/bg-blue-jumbo.jpg');
	background-position: center;
  	background-size:cover;
  	padding-top:0;
  	padding-bottom:0;

  	&.full-image{
  		position:relative;
  		height: 800px;
  		background-color: rgba(0, 0, 0, .25);

  		.jumbo-text-container{
	  		height: 800px;

	  		.text-box{
	  			h1,h3,p,ul>li{
	  				color:#ffffff !important;
	  			}
	  		}
	  	}
  	}

  	&.video{
  		background:transparent;
  		position:relative;
  		height: 800px;
  		background-color: rgba(0, 0, 0, .25);

  		.jumbo-text-container{
	  		height: 800px;

	  		.text-box{
	  			h1,h3,p,ul>li{
	  				color:#ffffff !important;
	  			}
	  		}
	  	}

  		#video-container {
			position: absolute;
			z-index:-1;
		}

		#video-container {
			top:0%;
			left:0%;
			height:100%;
			width:100%;
			overflow: hidden;
		}
		video {
			position:absolute;
			
		}
		video.fillWidth {
			/* Make video to at least 100% wide and tall */
			  min-width: 100%; 
			  min-height: 100%; 
			  
			  /* Setting width & height to auto prevents the browser from stretching or squishing the video */
			  width: auto;
			  height: auto;
			  
			  /* Center the video */
			  position: absolute;
			  top: 50%;
			  left: 50%;
			  transform: translate(-50%,-50%);
		}
  	}

  	img{
  		width: 100%;
  		height: auto;
  	}

  	.jumbo-text-container{
  		display: table;
  		width: 100%;
  	}

  	.text-box{
  		display: table-cell;
  		vertical-align: middle;

  		img.logo{
	  		max-width: 100%;
	  		margin-bottom: 20px;
	  		display: block;
	  	}

  		h1{
			text-align: left;
			font-family: $futura-medium;
			color: $dark-blue;
			font-size: 54px;
		}

		h3{
			text-align: left;
			font-family: $futura-medium;
			color: $dark-blue;
			font-size: 22px;
		}

		p{
			text-align: left;
			font-family: $futura-book;
			color: $black;
			font-size: 20px;
			margin-bottom: 20px;
		}

		ul{
			li{
				text-align: left;
				font-family: $futura-book;
				color: $black;
				font-size: 20px;
			}
		}
  	}
}