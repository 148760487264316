footer #menu{
	.navbar-nav>li {
		&.menu-item-has-children:hover>a, &.current-menu-parent>a, &.current-menu-item.menu-item-has-children>a{
			&:after{
				display: none;
			}
		}
	}
}

#menu{
    height: 130px; 
	width: 100%;
	padding: 0 0px;
	background:#ffffff;
	border-top: 10px solid $main-blue;


	.navbar-header img{
		padding: 20px 0 20px 0;
		height:120px;
		width: auto;

		@media only screen and (max-width : 1200px) {
	    	padding: 40px 0 40px 0;
	    }
	}

	.navbar-default{
		background-color:transparent;
		border-color: transparent;
		margin-bottom:0px;
		border: 0;
		display: flex;
		justify-content: space-between;
		position:static;
	}

	.navbar-collapse{
		border:none;
		flex:1;
		display: flex !important;
		justify-content: center;
	}

	ul.nav	{
		margin: 40px 0 0 0;

		li:hover>ul, li.current-menu-item>ul, li.current-menu-parent>ul{
			display: flex;
			justify-content: center;
		}

		ul{
			display: none;
			list-style: none;
			margin: 0px;
			background-color: $main-blue;
			padding:0;
			z-index: 999999999;
			position: absolute;
			left:0;
			margin-top:15px;
			width: 100%;

			>li{
				float: left;

				>a{
					font-family: $futura-medium;
					font-size: 17px;
					color: #ffffff;

					margin: 0px 20px 0 20px;
					padding: 15px 0px;
					text-align: left;

					display: block;

					@media only screen and (max-width : 1500px) {
						margin: 0px 5px 0 5px;
						padding: 5px 5px;
						font-size: 14px;
		    		}
				}

				a:hover, &.current-menu-item>a{
					border-bottom:none;
					text-decoration: underline;
				}
			}
		}
	}

	.navbar-nav>li {

		float: left;
		padding-bottom:20px;
		position:static;
		>a	{
			font-family: $futura-medium;
			font-size: 20px;
			color: $black;

			margin: 0px 20px 0 20px;
			padding: 10px 0px;
			text-align: left;

    		@media only screen and (max-width : 1500px) {
    			margin: 0px 5px 0 5px;
				padding: 5px 5px;
				font-size: 14px;
    		}
		}

		a:hover, &.current-menu-item>a{
			border-bottom:1px solid $black;
		}

		&.menu-item-has-children:hover>a, &.current-menu-item.menu-item-has-children>a, &.current-menu-parent>a{
			&:after{
				content: '';
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 0 15px 15px 15px;
				border-color: transparent transparent $main-blue transparent;
				position: absolute;
				bottom: -20px;
				left: 10%;
			}
		}
	}

	.social-links{
		float:right;
		margin: 40px 0 0 0;
	}
}


/* #Responsive meny
================================================== */

.toggle-id-1 {
	color: $main-blue;
	font-size: 38px;
	height: 50px;
	width: 50px;
	position: absolute;
	top: 10px;
	display: none;
	right: 1px;
}

.close-menu {
	color: white;
	font-size: 34px !important;
	float: right;
	margin-top: 15px;
	margin-right: 20px;
}

.responsive-menu-container {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 70px;
	padding-bottom:100px;
	overflow-y: scroll;
	background: $main-blue;
	overflow: -moz-scrollbars-none;
	-ms-overflow-style: none;
	left: 0;
	z-index: 999999999;
	opacity: 1;
	padding-left: 30px;
	padding-top:30px;
	display:none;
}

.responsive-menu-container::-webkit-scrollbar { width: 0 !important }

.responsiveMenuImage {
	padding: 15px 0 15px 20px;
	height: 70px;
}

.responsiveMenu a:hover, .responsiveMenu .current_page_item{
	text-decoration: none;
}

.responsiveMenu {
	list-style-type: none;
	margin-left: 0px;
	padding-left: 0px;
}

.responsiveMenu li a{
	color: white;
	display: block;
	font-size: 22px;
	padding: 10px;
	text-align: left;
	font-family: $futura-medium;
	text-transform: uppercase;
}

.responsiveMenu li ul{
	list-style: none;
	padding: 0;
	padding-left: 30px;

	li > a{
		font-size: 16px;
	}
}

.responsiveMenu li a:hover, .responsiveMenu li.current-menu-item>a { 
	text-decoration: none;
	font-weight: 800;
}

.responsiveMenu ._mi {
	border-right: 0px;
}

/* Medium Devices, Desktops */
@media only screen and (max-width : 992px) {


	.toggle-id-1 {
		display: inline-block;
	}

	.navbar-header {
		width: 100%;
	}

	#menu {
		background: #ffffff !important;
		position: fixed;
		top: 0;
		height: 70px;
		z-index: 999999;
		border-top: 5px solid $main-blue;
		.navbar-nav {
			display: none;
		}
		.navbar-header img {
			margin: 0px;
			height: 70px !important;
			padding: 20px 0 20px 0 !important;
		}

		.social-links{
			display: none;
		}
	}

	footer #menu{
		display: none;
	}
}

/* ================================================== */


