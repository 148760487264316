h1 {

}

p {

}

a{

}


.button{
	background: $dark-blue;
	color:#ffffff;
	font-family: $futura-medium;
	font-size: 18px;
	padding: 10px 25px;
	display: inline-block;
	border:none;
	text-align: center;

	&:hover{
		background: $main-blue;
		color: #ffffff;
		text-decoration: none;
	}
}