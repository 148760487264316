footer{
	#menu{
		border-top: none !important;
	}

	#bottom-footer{
		background: $main-blue;

		p{
			text-align: center;
			color:#ffffff;
			font-family: $futura-book;
			font-size: 16px;
			padding: 10px 0;
			margin:0;
		}
	}
}