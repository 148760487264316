/*
Theme Name: Besins Healthcare SE
Theme URI: http://www.pixl.se
Description: En hemsida av Pixl
Version: 1.0
Author: Pixl Webbyrå Sweden AB
Author URI: http://www.pixl.se
*/

@import 'partials/variables';
@import 'partials/elements';
@import 'partials/header';
@import 'partials/jumbotron';
@import 'partials/theme';
@import 'partials/footer';

@import 'partials/startpage';
@import 'partials/testobrist';
@import 'partials/testofilmer';
@import 'partials/halsa';
@import 'partials/contact';