#testobrist{
	padding-top: 0px;
	padding-bottom: 60px;

	#testo-film{
		background: #eeeeee;
		margin-top:60px;

		img{
			width: 100%;
			max-width: 100%;
			@media only screen and (min-width : 992px) {
				margin-left: 15px;
			}
		}

		.text-box{
			padding:30px;

			h2{
				text-align: left;
				font-family: $futura-medium;
				color: $dark-blue;
				font-size: 34px;
				margin-bottom: 20px;
			}

			p{
				text-align: left;
				font-family: $futura-book;
				color: $black;
				font-size: 20px;
				margin-bottom: 20px;
			}
		}
	}

	#news-section{
		margin-top:60px;
		text-align: center;

		h2{
			text-align: left;
			font-family: $futura-demibold;
			color: $black;
			font-size: 24px;
			margin-bottom: 20px;

			img{
				display: inline-block;
				margin-left: 10px;
			}
		}

		.news-article{
			text-align: left;

			h3{
				text-align: left;
				font-family: $futura-medium;
				color: $dark-blue;
				font-size: 28px;
				border-bottom:1px solid $black;
				padding-bottom:10px;
				margin-bottom: 10px;
			}

			p{
				text-align: left;
				font-family: $futura-book;
				color: $black;
				font-size: 18px;
				margin-bottom: 40px;

				&.date{
					font-size: 16px;
					float:right;
				}
			}

			a{
				text-align: left;
				font-family: $futura-medium;
				color: $black;
				font-size: 18px;

				img{
					display: inline-block;
					margin-right: 10px;
				}
			}
		}

		.button{
			margin-top: 40px;
		}
	}

	#expert-film{
		background: #eeeeee;
		margin-top:60px;

		img{
			width: 100%;
			max-width: 100%;
			@media only screen and (min-width : 992px) {
				margin-left: -15px;
			}
		}

		.text-box{
			padding:30px;

			h2{
				text-align: left;
				font-family: $futura-medium;
				color: $dark-blue;
				font-size: 34px;
				margin-bottom: 20px;
			}

			p{
				text-align: left;
				font-family: $futura-book;
				color: $black;
				font-size: 20px;
				margin-bottom: 20px;
			}
		}
	}
}